.loginView {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f8f8f8;
    flex-direction: column;

    .logo {
        display: block;
        margin: auto;
    }

    .cardContainer {
    }
}
