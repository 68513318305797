.deviceDetails {
    .ant-tabs-content-holder {
        display: flex;
        align-items: stretch;
    }

    .ant-card-body {
        padding: 0;
    }

    .ant-tabs-nav {
        margin-bottom: 0 !important;
    }
}
