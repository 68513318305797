.header {
    background-color: #fff !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.trigger {
    font-size: 18px;
    padding: 0 16px;
}

.logo {
    height: 64px;
    padding: 10px;
}
