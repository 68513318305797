body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

html,
body {
  width: 100%;
  height: 100%;
}

#root {
  width: 100%;
  height: 100%;
}

.main-panel {
  & > .content {
    margin-top: 79px;
    display: flex;
    flex-direction: column;
  }
}

.content-container {
  flex: 1 1 auto;
}

.ag-root-wrapper {
  .ag-react-container {
    width: 100%;
    height: 100%;
  }
}

.card {
  box-shadow: 0 4px 10px 0 rgb(20 19 34 / 3%), 0 0 10px 0 rgb(20 19 34 / 2%);
}
